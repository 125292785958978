<template>
  <div v-if="store" class="min-h-screen">
    <div class="relative">
      <StoresSlider :store="store" />
    </div>

    <StoresMetaTile v-if="!isMobileOrTablet" :store="store" />

    <HomeSwiperMaterials
      :title="t('offers_discounts')"
      :fetch-route="domainRoutes.materialsByServer"
      :route-title="{ path: '/offers' }"
      :fetch-params="{
        store_id: store.id,
        offersOnly: true
      }"
    />

    <HomeOffersGroupSlider
      :query="{
        'store_ids[]': [store.id],
        limit: 1000
      }"
    />

    <MaterialsCatogriesWithSupportFilter
      :support-filter="store.support_filters === 1"
      :use-categories-domain="true"
      :fetch-params="{ store_id: store.id }"
    />

    <MaterialsGrid
      class="mb-5 e_store-container"
      :fetch-route="domainRoutes.materialsByServer"
      with-query-params
      :fetch-params="{ store_id: store.id }"
      manual
      :items-per-page="20"
    />

    <HomeSwiperMaterials
      key-cache="featured_materials"
      :title="t('featured_materials')"
      :fetch-route="domainRoutes.materialsByServer"
      :fetch-params="{
        is_featured: true,
        store_id: store.id
      }"
    />
    <HomeSwiperMaterials
      v-if="store?.show_newest_materials"
      :fetch-route="domainRoutes.materialsByServer"
      :title="t('Recently_added')"
      :fetch-params="{
        order_by_latest: 1,
        store_id: store.id
      }"
    />
    <HomeSwiperMaterials
      v-if="store?.show_most_requested_materials"
      :title="t('the_most_demanded')"
      :fetch-route="domainRoutes.materialsMostRequestedByServer"
      :fetch-params="{ store_id: store.id }"
    />
    <HomeSwiperMaterials
      v-if="store?.show_most_viewed_materials"
      :title="t('most_watched')"
      :fetch-route="domainRoutes.materialsByServer"
      :fetch-params="{
        order_by_view_number: 1,
        store_id: store.id
      }"
    />
    <StoresRelatedStores :related-stores="store.related_stores" />
    <AdSwiper />
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
const { url } = useDomainHost()
const { store } = useDomainState()

const { isMobileOrTablet } = useDevice()

const { t } = useI18n()
const { isAuthLoggedIn } = useMenuModel()
const domainRoutes = getAllRoutes(isAuthLoggedIn()).materialsRoutes.domainRoutes

useServerSeoMeta(
  useOgMeta(url, store.value?.name, store.value?.about, store.value?.thumb)
)

useHead({
  title: store.value?.name,
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/')],
  script: [useSeoBreadcrumb([useSeoBreadcrumbItem(1, store.value?.name, url)])]
})
</script>
