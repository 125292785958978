<template>
  <div v-if="relatedStores?.length" class="e_store-container">
    <EStoreSwiper
      :items="relatedStores"
      class="my-6"
      :title="t('related_stores')"
      auto-slides
      swiper-navigation
    >
      <template #slide="{ item }">
        <div class="sm:px-1">
          <StoresCardSwiper
            :enable-navigate="false"
            class="transition-transform duration-300 transform hover:scale-105 mt-3"
            :store="(item as Store)"
            @click="goToRoute(item)"
          />
        </div>
      </template>
    </EStoreSwiper>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~~/composables/useMenuModel'

defineProps<{
  relatedStores: Store[] | undefined
}>()

const { t } = useI18n()

function goToRoute(item: Store) {
  navigateTo(`http://${item.domain}`, {
    open: {
      target: '_blank'
    }
  })
}
</script>
