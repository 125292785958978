<template>
  <div v-if="materials?.length || isError" class="mx-auto mt-5">
    <div v-if="isError">
      <EStoreFetchError :message="t('fetch_failed', { data: title })" />
    </div>

    <div v-else-if="materials?.length">
      <EStoreSwiper
        :items="materials"
        :title="title"
        :route-title="routeTitle"
        auto-slides
        swiper-navigation
        :view-more="viewMore"
      >
        <template #slide="{ item }">
          <MaterialsCard
            :key="`requested-grid-item-${item.id}`"
            class-card="w-[8rem] xs:w-[9rem] sm:w-[13.5rem]"
            class="transition-all duration-500 transform hover:scale-105 mt-3"
            :material-props="(item as Material)"
          />
        </template>
      </EStoreSwiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Material } from '~~/composables/useMenuModel'
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    fetchParams?: object
    title: string
    viewMore?: any
    routeTitle?: any
    fetchRoute?: string
  }>(),
  {
    fetchParams: () => ({}),
    fetchRoute: undefined,
    viewMore: undefined,
    routeTitle: undefined
  }
)

const materials = ref<Material[] | null>(null)
const isError = ref(false)

async function fetchMaterials() {
  const { data, error } = await useBasicFetch<ApiResponse<Material[]>>(
    props.fetchRoute ||
      getAllRoutes().materialsRoutes.shippingRoutes.materialsByServer,
    {
      query: {
        ...props.fetchParams
      }
    }
  )
  if (error.value != null) {
    isError.value = true
  }
  return data.value?.data
}
if (!materials.value?.length) {
  const materialsTemp = await fetchMaterials()
  if (materialsTemp?.length) {
    materials.value = materialsTemp
  }
}
</script>
