<template>
  <div class="h-full px-1 lg:px-2">
    <EStoreLink
      class="widthCard h-full flex flex-col text-text_color cursor-pointer text-[0.65rem] sm:text-xs"
      :to="enableNavigate ? '/': undefined"
    >
      <div class="aspect-[1]">
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="storeLocal.icon"
          :alt="storeLocal.name"
          width="216"
          height="216"
          class="w-full h-full rounded-[14px] object-cover"
        />
      </div>
      <div class="flex items-start p-1 pt-2">
        <div
          v-if="storeCity && settings.show_city_name == '1'"
          class="flex text-e_store-light items-center flex-shrink-0"
        >
          <v-icon icon="mdi mdi-map-marker-outline" :size="16" />
          <div>{{ storeCity }} /</div>
        </div>
        <span class="font-bold break-words px-0.5 flex-shrink-1">
          {{ storeLocal.name }}
        </span>
      </div>
      <div class="flex-auto"></div>
      <div
        class="text-e_store-light text-overflow"
        v-html="storeLocal.about"
      ></div>
    </EStoreLink>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'
import type { Store } from '~~/composables/useMenuModel'
const { getCityByCityId } = usePlacesStore()
const { storeModel } = useMenuModel()
const props = withDefaults(
  defineProps<{
    store: Store
    enableNavigate?: boolean
  }>(),
  {
    enableNavigate: true
  }
)
const { settings } = useSettings()
const storeLocal = storeModel(props.store)
const storeCity = computed(() => {
  return getCityByCityId(storeLocal.city_id)?.name
})
</script>

<style>
.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.custom-bg {
  @apply bg-bg_color text-text_color !important;
}
.widthCard {
  width: 45vw;
}
@media (min-width: 650px) {
  .widthCard {
    width: 12rem;
  }
}
</style>
